import { twMerge } from "tailwind-merge";
import clsx, { type ClassValue } from "clsx";
import { typos } from "../styles/typography";

const generateTailwindStyleFromClass = (className: string) => {
  const style =
    typos[String(className).startsWith(".") ? className : `.${className}`];
  if (!style) return className;

  let result = "";
  if (style.fontSize) {
    result += `font-sans text-[${style.fontSize}]`;
  }
  if (style.lineHeight) {
    result += ` leading-[${style.lineHeight}]`;
  }
  if (style.fontWeight) {
    result += ` font-[${style.fontWeight}]`;
  }
  return result.trim();
};

export const customTwMerge = (...classes: ClassValue[]) => {
  const convertedClasses = classes.flat(5).map((className) => {
    return typeof className === "string"
      ? generateTailwindStyleFromClass(className)
      : className;
  });
  return twMerge(clsx(...convertedClasses));
};
