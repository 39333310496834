import clsx from "clsx";
import { ButtonAppearance, ButtonSize } from "./Button.type";

export function createButtonSizeStyle({
  size,
  hasIcon,
}: {
  size: ButtonSize;
  hasIcon?: boolean;
}) {
  switch (size) {
    case "extraBig": {
      return clsx(
        "c16-medium rounded-[6px] py-[14px] pr-[16px]",
        hasIcon ? "pl-[14px]" : "pl-[16px]",
      );
    }
    case "big": {
      return clsx(
        "c16-medium rounded-[6px] py-[12px] pr-[16px]",
        hasIcon ? "pl-[14px]" : "pl-[16px]",
      );
    }
    case "medium": {
      return clsx(
        "c14-medium min-w-[74px] rounded-[5px] py-[11px] pr-[14px]",
        hasIcon ? "pl-[12px]" : "pl-[14px]",
      );
    }
    case "small": {
      return clsx(
        "c14-medium min-w-[74px] rounded-[5px] py-[8px] pr-[14px]",
        hasIcon ? "pl-[12px]" : "pl-[14px]",
      );
    }
  }
}

export const BUTTON_APPEARANCE_DEFAULT_STYLE_MAP: {
  [key in ButtonAppearance]: string;
} = {
  default: clsx("bg-background-button-default text-text-default"),
  primary: clsx("bg-background-brand-default text-text-inverse"),
  outlined: clsx(
    "bg-background-neutral-default shadow-border-default text-text-default shadow-[inset_0_0_0_1px]",
  ),
  secondary: clsx(
    "bg-background-neutral-default shadow-background-brand-default text-background-brand-default shadow-[inset_0_0_0_1px]",
  ),
};

export const BUTTON_APPEARANCE_HOVER_STYLE_MAP: {
  [key in ButtonAppearance]: string;
} = {
  default: clsx("hover:bg-background-button-subtler hover:text-text-default"),
  primary: clsx("hover:bg-background-brand-hover hover:text-text-inverse "),
  outlined: clsx(
    "hover:shadow-background-brand-default hover:text-text-default hover:bg-background-neutral-default hover:shadow-[inset_0_0_0_1px]",
  ),
  secondary: clsx(
    "hover:bg-background-button-subtlest hover:shadow-background-brand-default hover:text-background-brand-default hover:shadow-[inset_0_0_0_1px]",
  ),
};

export const BUTTON_APPEARANCE_PRESSED_STYLE_MAP: {
  [key in ButtonAppearance]: string;
} = {
  default: clsx("active:bg-background-button-subtle active:text-text-default"),
  primary: clsx("active:bg-background-brand-pressed active:text-text-inverse"),
  outlined: clsx(
    "active:shadow-background-brand-default active:text-background-brand-default active:bg-background-button-subtlest active:shadow-[inset_0_0_0_1px]",
  ),
  secondary: clsx(
    "active:shadow-background-brand-default active:bg-background-brand-subtler active:text-background-brand-default active:shadow-[inset_0_0_0_1px]",
  ),
};

export const BUTTON_APPEARANCE_DISABLED_STYLE_MAP: {
  [key in ButtonAppearance]: string;
} = {
  default: clsx(
    "disabled:bg-background-button-disabled disabled:text-text-disabled",
  ),
  primary: clsx(
    "disabled:bg-background-button-disabled disabled:text-text-disabled",
  ),
  outlined: clsx(
    "disabled:bg-background-neutral-default disabled:shadow-border-disabled disabled:text-text-disabled disabled:shadow-[inset_0_0_0_1px]",
  ),
  secondary: clsx(
    "disabled:bg-background-neutral-default disabled:shadow-border-disabled disabled:text-text-disabled disabled:shadow-[inset_0_0_0_1px]",
  ),
};

export const BUTTON_APPEARANCE_SELECTED_STYLE_MAP: {
  [key in ButtonAppearance]: string;
} = {
  default: clsx("bg-background-brand-subtle text-background-brand-default"),
  primary: clsx("bg-background-brand-subtle text-background-brand-default"),
  outlined: clsx(
    "bg-background-neutral-default text-background-brand-default shadow-background-brand-default shadow-[inset_0_0_0_1px]",
  ),
  secondary: clsx(
    "bg-background-brand-subtle text-background-brand-default shadow-[inset_0_0_0_1px] shadow-[transparent]",
  ),
};
