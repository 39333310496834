const FONT_FAMILY =
  '"Wanted Sans Variable", "Wanted Sans", -apple-system, BlinkMacSystemFont, system-ui, "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif';

const typos: Record<
  string,
  {
    fontSize: string;
    lineHeight: string;
    fontWeight: string;
    fontFamily?: string;
  }
> = {
  ".h28-semibold": {
    fontSize: "28px",
    lineHeight: "36px",
    fontWeight: "600",
  },
  ".h28-regular": {
    fontSize: "28px",
    lineHeight: "36px",
    fontWeight: "400",
  },
  ".h24-semibold": {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: "600",
  },
  ".h24-regular": {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: "400",
  },
  ".h22-semibold": {
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: "600",
  },
  ".h22-regular": {
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: "400",
  },
  ".h20-semibold": {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "600",
  },
  ".h20-regular": {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "400",
  },
  ".h18-semibold": {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: "600",
  },
  ".h18-regular": {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: "400",
  },

  ".p16-semibold": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "600",
  },
  ".p16-medium": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
  },
  ".p16-regular": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
  },
  ".p14-semibold": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "600",
  },
  ".p14-medium": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "500",
  },
  ".p14-regular": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "400",
  },

  ".c16-semibold": {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "600",
  },
  ".c16-medium": {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "500",
  },
  ".c16-regular": {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "400",
  },
  ".c14-semibold": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "600",
  },
  ".c14-medium": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "500",
  },
  ".c14-regular": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
  },
  ".c12-semibold": {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "600",
  },
  ".c12-medium": {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "500",
  },
  ".c12-regular": {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "400",
  },
};

Object.keys(typos).forEach((key) => (typos[key].fontFamily = FONT_FAMILY));

export { typos };
